/* CustomGlobe.css */
.globe-container {
    width: 480px;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto; /* Center the globe horizontally */
    border-radius: 5px;
  }

  .image-container {
    position: absolute;
    border: 2ps solid white;
    top: 380px; /* Adjust top position as needed */
    left: 10px; /* Adjust left position as needed */
    z-index: 10; /* Ensure the image is above the globe */
  }

  .controler-container{
    position: absolute;
    border: 2ps solid white;
    top: 10px; /* Adjust top position as needed */
    left: 440px; /* Adjust left position as needed */
    z-index: 10; /* Ensure the image is above the globe */
    cursor: pointer;
  }
  
  /* Overlay for stars */
  .starry-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><circle cx="50%" cy="50%" r="1" fill="white"/><circle cx="10%" cy="20%" r="1" fill="white"/><circle cx="30%" cy="70%" r="1" fill="white"/><circle cx="70%" cy="40%" r="1" fill="white"/><circle cx="90%" cy="80%" r="1" fill="white"/><circle cx="20%" cy="90%" r="1" fill="white"/><circle cx="80%" cy="10%" r="1" fill="white"/><circle cx="60%" cy="60%" r="1" fill="white"/><circle cx="40%" cy="30%" r="1" fill="white"/><circle cx="5%" cy="50%" r="1" fill="white"/><circle cx="85%" cy="70%" r="1" fill="white"/><circle cx="65%" cy="20%" r="1" fill="white"/><circle cx="35%" cy="80%" r="1" fill="white"/><circle cx="15%" cy="40%" r="1" fill="white"/><circle cx="55%" cy="90%" r="1" fill="white"/><circle cx="75%" cy="10%" r="1" fill="white"/><circle cx="45%" cy="60%" r="1" fill="white"/><circle cx="25%" cy="30%" r="1" fill="white"/><circle cx="95%" cy="50%" r="1" fill="white"/></svg>'); */
    opacity: 0.5; /* Adjust the opacity of stars */
    pointer-events: none; /* Prevents the overlay from blocking interaction with the globe */
  }
  
  .globe-label {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  
  
  .globe-label {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: white;
    position: absolute;
    top: 10px;
    left: 10px;
  }


  @media screen and (max-width : 516px){
     .globe-container{
      width: 350px !important;
      height: 350px !important;
     }
     .image-container {
      position: absolute;
      border: 2ps solid white;
      top: 260px; /* Adjust top position as needed */
      left: 10px; /* Adjust left position as needed */
      z-index: 10; /* Ensure the image is above the globe */
      width: 80px;
      height: 40px;
    }
    .controler-container{
      left: 310px;
    }
  }
  