.video-container {
  position: relative;
  width: 480px;
  height: 480px;
  overflow: hidden;
}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controls-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 40px;
  z-index: 10;
}

.control-button {
  color: white;
  cursor: pointer;
  display: none;
}

.control-pause {
  color: white;
  cursor: pointer;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 5;
}

.video-box:hover::after {
  opacity: 1;
}

.centered-container:hover .control-button {
  display: block;
}

.topControls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  padding: 10px;
  box-sizing: border-box;
}

.label {
  position: absolute;
  top: 86%;
  left: 50%;
  width: 460px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.label1 {
  position: absolute;
  top: 82%;
  left: 50%;
  width: 460px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  z-index: 10;
}



.bar {
  position: absolute;
  top: 93%;
  left: 50%;
  width: 460px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.volume-control {
  display: none;
}

.topControls:hover .volume-control {
  display: block;
}

@media screen and (max-width : 510px){
  .video-container{
    width: 350px !important;
    height: 350px !important;
  }
  .label{
    width: 340px !important;
  }
  .bar{
    width: 340px !important;
  }
}
