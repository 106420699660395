.anime {
background-image: url(../../../assest/images/map.jpg);
}

h1 {
  width: 100%;
  line-height: 99%;
  overflow-x: hidden;
  text-align: center;
  white-space: nowrap;
  transition: all 1s linear;
}

.anime:hover h1 {
  width: 0%;
 
}

.vijay{
  position: relative;
  background-size: contain;
  width: 480px;
  height: 480px;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 10px;
  transition: background-color 0.5s ease; /* Smooth transition for background color */
  background-color: rgba(0, 0, 0, 0);
}

#myinput {
  border-radius: 8px;
  height: 8px;
  outline: none;
  -webkit-appearance: none;
  position: absolute;
}

.volumeTab{
   display: none;
   transition: 10s ease-in;
}
.speaker:hover + .volumeTab{
    display: block;
}
.pauseSpeaker:hover + .volumeTab{
  display: block;
}


.speaker,.globe,.controls,.runningbar,.details,.timimg{
  display: none;
  transition: 4s ease-in;
}





/* .vijay:hover + .speaker,.globe,.controls,.details,.runningbar,.timimg{
  display: block;
} */


.vijay:hover .speaker{
  display: block;
}
.vijay:hover .globe{
  display: block;
}
.vijay:hover .controls{
  display: block;
}
.vijay:hover .details{
  display: block;
}
.vijay:hover .runningbar{
  display: block;
}
.vijay:hover .timimg{
  display: block;
}