.tabs:hover{
    border: 1px solid #8A59FF;
}

.tabsHover:hover{
    background: 'rgb(69, 33, 182)';
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.textFiled:focus{
  outline: none;
}

.textFiled{
    padding: 10px;
    padding-top:10px;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
    color:white !important
}
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    color: white !important;
    
    
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: white !important;
    color: white!important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    color: white !important;
} */
/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline.containerrrr{
    border-color: white !important;
    color: white !important;

 } */

.textInputFiled{
    color: azure !important
}

.SingInIput:focus{
    background-color: red;}

    .textInputFiled {
       
        color: white !important
    }
    @keyframes bookOpen {
        0% {
          transform: scaleY(0) rotateX(90deg);
          opacity: 0;
        }
        100% {
          transform: scaleY(1) rotateX(0deg);
          opacity: 1;
        }
      }
      
      @keyframes bookClose {
        0% {
          transform: scaleY(1) rotateX(0deg);
          opacity: 1;
        }
        100% {
          transform: scaleY(0) rotateX(90deg);
          opacity: 0;
        }
      }
      
      /* Modal Styles */
     /* App.css */
@keyframes bookOpen {
    0% {
      transform: scaleY(0) rotateX(90deg);
      opacity: 0;
    }
    100% {
      transform: scaleY(1) rotateX(0deg);
      opacity: 1;
    }
  }
  
  @keyframes bookClose {
    0% {
      transform: scaleY(1) rotateX(0deg);
      opacity: 1;
    }
    100% {
      transform: scaleY(0) rotateX(90deg);
      opacity: 0;
    }
  }
  
  .bookOpen {
    animation: bookOpen 0.5s ease-out;
  }
  
  .bookClose {
    animation: bookClose 0.5s ease-out;
  }
  .loader {
    width: 64px;
    height: 64px;
    position: relative;
    background-image:
      linear-gradient(#FFF 16px, transparent 0) ,
      linear-gradient(#FF3D00 16px, transparent 0) ,
      linear-gradient(#FF3D00 16px, transparent 0) ,
      linear-gradient(#FFF 16px, transparent 0);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: left top , left bottom , right top , right bottom;
    animation: rotate 1s linear infinite;
  }
  @keyframes rotate {
    0% {
      width: 64px;
      height: 64px;
      transform: rotate(0deg)
    }
    50% {
      width: 30px;
      height: 30px;
      transform: rotate(180deg)
    }
    100% {
      width: 64px;
      height: 64px;
      transform: rotate(360deg)
    }
  }
