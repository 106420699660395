.back-image{
    filter: blur(15px);
  -webkit-filter: blur(15px);
  
  /* Full height */
  
  /* Center and scale the image nicely */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 24rem;
}

.bg-text {
    background-color: linear-gradient(to bottom,rgba(0,0,0,.9) 0%,rgba(0,0,0,.7) 10%,rgba(0,0,0,.2) 50%,rgba(0,0,0,.2) 100%); /* Fallback color */
   /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
  } 

  #joinInput{
    height: 4px;
    border-radius: 2px;
    outline: none;
    -webkit-appearance: none;
    position: absolute;
  }

  .footer {
    position: fixed;
    bottom: -200px; /* Initially hidden */
    left: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px;
    transition: bottom 0.5s ease-in-out, z-index 0s 0.5s; /* Added z-index transition */
    z-index: -1; /* Initially behind other content */
  }

  .show{
    position: fixed;
  z-index: 2000; /* In front of other content */
  transition: bottom 0.5s ease-in-out; /* Adjust transition for visibility */
  top:90%;
  width: 100%;
  bottom: 0;
  }