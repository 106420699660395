.css-17ey3p3-MuiButtonBase-root-MuiButton-root{
    text-transform: none;
}

.search-box:focus{
    outline: none;
}

/* @media screen and (min-width : ) */

@media screen and (max-width : 545px){
    .topnav-icons{
        display: none !important;
    }
}

@media screen and  (max-width : 410px){
    .subscribeIcon{
        display: none !important;
    }
}